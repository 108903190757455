.navbar li .glyphicon,
.navbar li svg {
    margin-right: 10px;
}

.navbar li svg {
    width: 30px !important;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #4189C7;
    color: white;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
        width: calc(15% - 20px);
        min-width: 200px;

        .navbar-collapse {
            border-top: 1px solid #444;
            padding: 0;
        }

        .container-fluid {
            padding: 0;
            margin: 0;

            .navbar-brand {
                margin: 0;
            }
        }

        ul {
            float: none;
        }

        li {
            float: none;
            font-size: 15px;
            margin: 6px;

            a {
                padding: 10px 16px;
                border-radius: 4px;
                display: flex;
                align-content: center;
            }

            svg {
                font-size: 1.5em;
            }
        }

        a {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    #menuWhitespace {
        height: 100%;
        width: calc(15% - 20px);
        min-width: 200px;
    }

    .navbar-header {
        float: none;
    }
}
